import { FabricDeleteEvent } from '@/@types/fabric';
import { useClientLayoutEffect } from '@/src/hooks/useClientLayoutEffect';
import { useIntervalFn } from '@/src/hooks/useInterval';
import { useRandomIntervalFn } from '@/src/hooks/useRandomIntervalFn';
import { useReferencedFn } from '@/src/hooks/useReferencedFn';
import { useWindowEvent } from '@/src/hooks/useWindowEvent';
import { pick } from '@/src/lib/store';
import useDetectInactivity from '@/src/modules/assistant/hooks/useDetectInactivity';
import useIsLate from '@/src/modules/assistant/hooks/useIsLate';
import usePet from '@/src/modules/assistant/hooks/usePet';
import {
  MascotDarkModeStateMachine,
  MascotState,
  MascotStateActions,
  MascotStateMachine,
} from '@/src/modules/assistant/types';
import { useColorScheme } from '@/src/modules/ui/theme/useColorScheme';
import { preventForwardPropsConfig } from '@/src/modules/ui/utils/preventForwardProps';
import useAnimationStore from '@/src/store/animation';
import { Alignment, Fit, Layout, StateMachineInput, useRive } from '@rive-app/react-canvas-lite';
import { motion, useWillChange } from 'framer-motion';
import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { shallow } from 'zustand/shallow';

const AnimationCanvas = styled.canvas`
  position: absolute;
  width: 138px;
  height: 152px;

  pointer-events: none;
  bottom: 0;
  right: 0;
  z-index: 1;

  flex-shrink: 0;
`;

const RealButton = styled(motion.button)
  .attrs<{
    shouldFadeIn: boolean;
    isPetting: boolean;
  }>(({ shouldFadeIn, ...props }) => ({
    initial: { opacity: shouldFadeIn ? 0 : 1 },
    animate: {
      opacity: 1,
      transition: {
        delay: 0.3,
      },
    },
    exit: { opacity: 0 },
    ...props,
  }))
  .withConfig(preventForwardPropsConfig(['shouldFadeIn', 'isPetting']))`
  position: relative;

  width: 52.8px;
  height: 52.8px;
  flex-shrink: 0;

  z-index: 35;

  transform: scale(0.9);
  transition: transform 0.2s ease-in-out;

  background-color: transparent;

  touch-action: none;

  &:hover {
    transform: scale(1);
  }

  ${(p) =>
    p.isPetting &&
    `
    cursor: grabbing;
  `};

  &:active::after {
    content: '';
    position: absolute;
    width: 200%;
    height: 200%;
    top: -50%;
    left: -50%;
  }
`;

const AnimatedMascotButton: React.FC<{
  onClick?: () => void;
  wasChatAssistantOpen?: boolean;
}> = ({ onClick, wasChatAssistantOpen = false }) => {
  const willChange = useWillChange();
  const { parsedColorScheme } = useColorScheme();

  const { pageLoadAssistantButton, setPageLoadAssistantButton } = useAnimationStore(
    (MascotState) => pick(MascotState, ['pageLoadAssistantButton', 'setPageLoadAssistantButton']),
    shallow,
  );

  const shouldFadeIn = useMemo(
    () => !pageLoadAssistantButton && !wasChatAssistantOpen,
    [pageLoadAssistantButton, wasChatAssistantOpen],
  );

  // To prevent the mascot from flashing the wrong color scheme on mount,
  // we load up the dark mode MascotState machine if the color scheme is dark
  const [activeStateMachines] = useState([
    MascotStateMachine,
    ...(parsedColorScheme === 'dark' ? [MascotDarkModeStateMachine] : []),
  ]);

  const { rive, setCanvasRef } = useRive({
    src: '/rive/assistant.riv',
    stateMachines: activeStateMachines,
    layout: new Layout({ fit: Fit.Contain, alignment: Alignment.Center }),
    autoplay: true,
  });

  /**
   * The way to change or trigger inputs is to use the hook useStateMachineInput(rive, stateMachineName, inputName) which
   * returns a StateMachineInput object with a function to trigger the input, change, or get the value.
   *
   * But this is a bit cumbersome with a lot of boilerplate, since we don't really need to read the value as well.
   *
   * This memo will get all the state machine inputs using rive?.stateMachineInputs(stateMachineName) and return an object:
   * {
   *  [inputName]: StateMachineInput
   * }
   *
   * This assumes there are no overlapping input names between state machines.
   */
  const stateMachineInputs = useMemo(() => {
    if (!rive) return null;

    return [MascotDarkModeStateMachine, MascotStateMachine].reduce<
      Record<string, StateMachineInput>
    >((acc, stateMachineName) => {
      const inputs = rive.stateMachineInputs(stateMachineName);

      if (!inputs || !Array.isArray(inputs)) return acc;

      for (const input of inputs) {
        acc[input.name] = input;
      }

      return acc;
    }, {});
  }, [rive]);

  const fireActionRef = useReferencedFn((action: MascotStateActions) => {
    stateMachineInputs?.[action]?.fire();
  });

  const setBooleanStateRef = useReferencedFn((state: MascotState, value: boolean) => {
    if (stateMachineInputs?.[state]) stateMachineInputs[state].value = value;
  });

  /**
   * This makes it so the Rive mascot is not squished or streched, which happens sometimes
   */
  useClientLayoutEffect(() => {
    rive?.resizeDrawingSurfaceToCanvas();
  }, [rive]);

  /**
   * Switches dark mode dynamically
   */
  useClientLayoutEffect(() => {
    if (!stateMachineInputs) return;

    setBooleanStateRef.current(MascotState.DARK_MODE, parsedColorScheme === 'dark');
    setBooleanStateRef.current(MascotState.LIGHT_MODE, parsedColorScheme === 'light');
  }, [parsedColorScheme, setBooleanStateRef, stateMachineInputs]);

  const { petAmount, isPetting, events } = usePet(onClick);

  const isHappy = petAmount > 40 && petAmount <= 80;
  const isLove = petAmount > 80;

  /**
   * Depending on the pet amount, we change the mascot's state,
   * it goes from IDLE -> HAPPY -> LOVE, and it has to go in that order, you can't go from IDLE -> LOVE.
   */
  useEffect(() => {
    if (isHappy) {
      fireActionRef.current(MascotStateActions.HAPPY);
    } else if (isLove) {
      // Run both animations because LOVE requires HAPPY to be run first
      fireActionRef.current(MascotStateActions.HAPPY);
      fireActionRef.current(MascotStateActions.LOVE);
    } else {
      fireActionRef.current(MascotStateActions.IDLE);
    }
  }, [isHappy, isLove, fireActionRef]);

  /**
   * Randomly blink every 10-20 seconds
   */
  useRandomIntervalFn(
    false,
    () => {
      fireActionRef.current(MascotStateActions.BLINK1);
    },
    10000,
    20000,
  );

  /**
   * After 30 seconds of inactivity, the mascot will go to sleep,
   * and wake up when the user is active again
   */
  const isAwake = useDetectInactivity({
    inactiveAfter: 30 * 1000,
    onInactive: () => {
      setBooleanStateRef.current(MascotState.SLEEP, true);
    },
    onActive: () => {
      setBooleanStateRef.current(MascotState.SLEEP, false);
    },
  });

  /**
   * If the user is late, the mascot will be visibly tired
   */
  const isLate = useIsLate();

  useEffect(() => {
    if (!stateMachineInputs) return;

    setBooleanStateRef.current(MascotState.TIRED, isLate && isAwake && petAmount < 10);
  }, [isLate, isAwake, petAmount, setBooleanStateRef, stateMachineInputs]);

  const [onCloseChatAnimated, setOnCloseChatAnimated] = useState(false);

  /**
   * If "wasChatAssistantOpen" is true, and the chat assistant was closed,
   * we will animate the mascot to wink or blink.
   *
   * We set "onCloseChatAnimated" to true to prevent this from happening again, important for when
   * opening an expanded item.
   */
  useEffect(() => {
    if (!wasChatAssistantOpen || onCloseChatAnimated || !stateMachineInputs) return;

    const t = window.setTimeout(() => {
      setOnCloseChatAnimated(true);

      const shouldAnimateRandom = Math.random();

      if (shouldAnimateRandom < 0.3) return;

      const shouldWink = Math.random();

      if (shouldWink <= 0.5) {
        fireActionRef.current(MascotStateActions.WINK);
      } else {
        fireActionRef.current(MascotStateActions.BLINK2);
      }
      // wait 1 second due to animations playing before the mascot shows visually for the user
    }, 1000);

    return () => {
      window.clearTimeout(t);
    };
  }, [wasChatAssistantOpen, onCloseChatAnimated, fireActionRef, stateMachineInputs]);

  useWindowEvent('fabric-folder-created', () => {
    // 30% chance to be dreaming
    const shouldDream = Math.random();
    if (shouldDream < 0.3) {
      fireActionRef.current(MascotStateActions.DREAMING);
    }
  });

  useWindowEvent('fabric-resource-created', () => {
    // 15% chance to be amazed
    const shouldAmazed = Math.random();
    if (shouldAmazed < 0.15) {
      fireActionRef.current(MascotStateActions.AMAZED);
    }
  });

  useEffect(() => {
    let timeout: number | undefined = undefined;
    const onResourcesDeleted = (e: CustomEvent<FabricDeleteEvent>) => {
      // 10% chance to be confused
      const shouldConfused = Math.random();
      if (shouldConfused < Math.min(0.4, e.detail.quantity / 10)) {
        setBooleanStateRef.current(MascotState.CONFUSED, true);

        timeout = window.setTimeout(() => {
          setBooleanStateRef.current(MascotState.CONFUSED, false);
        }, 5000);
      }
    };

    const setBooleanState = setBooleanStateRef.current;

    window.addEventListener('fabric-resources-deleted', onResourcesDeleted);

    return () => {
      window.removeEventListener('fabric-resources-deleted', onResourcesDeleted);

      timeout && window.clearTimeout(timeout);
      setBooleanState(MascotState.CONFUSED, false);
    };
  }, [setBooleanStateRef]);

  // every 3 minutes, there's a 10% to put on glasses, and 100% to remove them
  useIntervalFn(
    false,
    () => {
      const shouldPutOnGlasses = Math.random();
      if (shouldPutOnGlasses < 0.1) {
        setBooleanStateRef.current(MascotState.GLASS, true);
      } else {
        setBooleanStateRef.current(MascotState.GLASS, false);
      }
    },
    3 * 60 * 1000,
  );

  return (
    <>
      <RealButton
        shouldFadeIn={shouldFadeIn}
        isPetting={isPetting}
        onAnimationComplete={() => setPageLoadAssistantButton(true)}
        style={{ willChange }}
        {...events}
      >
        <AnimationCanvas ref={setCanvasRef} width={138} height={152} />
      </RealButton>
    </>
  );
};

export default AnimatedMascotButton;
