import { pick } from '@/src/lib/store';
import { mediaHover, mediaMobile } from '@/src/modules/ui/styled-utils';
import { cssVar } from '@/src/modules/ui/theme/variables';
import { preventForwardPropsConfig } from '@/src/modules/ui/utils/preventForwardProps';
import useUIStore from '@/src/store/ui';
import clsx from 'clsx';
import { animate, cubicBezier, motion, MotionValue } from 'framer-motion';
import React from 'react';
import styled, { css } from 'styled-components';
import { shallow } from 'zustand/shallow';
import { SIDEBAR_OPTIONS } from './constants';

type HandleProps = {
  x: MotionValue<number>;
};

const DragEase = cubicBezier(0.32, 0.72, 0, 1);

const Handle: React.FC<HandleProps> = ({ x }) => {
  const { sidebarPreferredWidth, setSidebarPreferredWidth } = useUIStore(
    (state) => pick(state, ['sidebarPreferredWidth', 'setSidebarPreferredWidth']),
    shallow,
  );

  const [startFromThumb, setStartFromThumb] = React.useState(false);
  const [dragging, setDragging] = React.useState(false);

  const animateX = (newX: number, animation = true) => {
    animate(
      x,
      Math.max(
        SIDEBAR_OPTIONS.UNEXPANDED_WIDTH,
        Math.min(SIDEBAR_OPTIONS.EXPANDED_WIDTH.MAXIMUM, newX),
      ),
      animation
        ? {
            ease: DragEase,
            duration: 0.2,
            delay: 0,
          }
        : {
            duration: 0,
          },
    );
  };

  const onClick = () => {
    if (dragging) return;

    if (x.get() <= SIDEBAR_OPTIONS.EXPANDED_WIDTH.MINIMUM) {
      if (sidebarPreferredWidth <= SIDEBAR_OPTIONS.EXPANDED_WIDTH.MINIMUM) {
        animateX(SIDEBAR_OPTIONS.EXPANDED_WIDTH.RECOMMENDED);
      } else {
        animateX(sidebarPreferredWidth);
      }
    } else {
      animateX(SIDEBAR_OPTIONS.UNEXPANDED_WIDTH);
    }
  };

  return (
    <motion.div
      className="hidden md:flex top-0 md:absolute bottom-0 -right-2 hover:cursor-col-resize px-2 pointer-events-none md:pointer-events-auto"
      onPanStart={() => {
        setDragging(true);
      }}
      onPan={(_, info) => {
        /**
         * for some reason, the animation is buggy when panning (not moving),
         * disabling it for now
         */
        animateX(info.point.x, false);
      }}
      onPanEnd={(e, info) => {
        setDragging(false);
        setStartFromThumb(false);
        animateX(info.point.x);

        e.stopPropagation();

        const newX = x.get();

        if (newX >= SIDEBAR_OPTIONS.EXPANDED_WIDTH.MINIMUM) {
          setSidebarPreferredWidth(
            Math.max(
              SIDEBAR_OPTIONS.EXPANDED_WIDTH.MINIMUM,
              Math.min(SIDEBAR_OPTIONS.EXPANDED_WIDTH.MAXIMUM, newX),
            ),
          );
        } else {
          animateX(SIDEBAR_OPTIONS.UNEXPANDED_WIDTH);
        }
      }}
      onPointerUp={onClick}
    >
      <motion.div
        className={clsx(
          'fixed inset-0 ',
          dragging ? 'block' : 'hidden',
          startFromThumb ? 'cursor-grabbing' : 'cursor-col-resize',
        )}
      />

      <HandleElementWrapper
        onTapStart={() => setStartFromThumb(true)}
        onTapCancel={() => setStartFromThumb(false)}
        isDragging={dragging}
      >
        <HandleElement />
      </HandleElementWrapper>
    </motion.div>
  );
};

export default Handle;

const HandleElement = styled.div`
  background: ${cssVar['color-sidebar-resize-thumb']};

  height: 50px;
  width: 4px;
  border-radius: 2px;
  opacity: 0.5;
  transition:
    opacity 200ms,
    height 200ms;
`;

const HandleElementWrapper = styled(motion.div).withConfig(
  preventForwardPropsConfig(['isDragging']),
)<{ isDragging?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: grab;

  width: 1.5rem;

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -0.75rem;

  ${mediaMobile} {
    display: none;
  }
  ${mediaHover} {
    &:hover {
      ${HandleElement} {
        opacity: 1;
        height: 60px;
      }
    }
  }

  ${(p) =>
    p.isDragging &&
    css`
      cursor: grabbing;
      ${HandleElement} {
        opacity: 1;
        height: 60px;
      }
    `}
`;
