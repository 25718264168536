'use client';

import React, { createContext, useContext, useEffect, useState } from 'react';
import Node from './Node';
import Tree from './Tree';
import { TreeContext } from './types';

// Context and provider for the tree view
const TreeViewContext = createContext<TreeContext | undefined>(undefined);

const Provider = ({ children }: { children: React.ReactNode }) => {
  const [openNodes, setOpenNodes] = useState<string[]>(
    JSON.parse(sessionStorage.getItem('openNodes') || '[]'),
  );
  const [focusedId, setFocusedId] = useState<string | null>(null);
  const [selectedId, setSelectedId] = useState<string | null>(null);

  useEffect(() => {
    sessionStorage.setItem('openNodes', JSON.stringify(openNodes));
  }, [openNodes]);

  return (
    <TreeViewContext.Provider
      value={{
        openNodes,
        setOpenNodes,
        focusedId,
        setFocusedId,
        selectedId,
        setSelectedId,
      }}
    >
      {children}
    </TreeViewContext.Provider>
  );
};

export const useTreeView = (): TreeContext => {
  const context = useContext(TreeViewContext);
  if (!context) {
    throw new Error('useTreeView must be used within a TreeViewProvider');
  }
  return context;
};

// TreeView API elements
export const TreeView = Object.assign(Tree, {
  Provider: Provider,
  Node: Node,
});
