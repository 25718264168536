import MessageForm from '@/src/components/Chatbot/MessageForm';
import MessagesList from '@/src/components/Chatbot/MessageList';
import { MessagesProvider } from '@/src/hooks/chatbot';
import { useResponsive } from '@/src/hooks/responsive';
import useDeferred from '@/src/hooks/useDeferred';
import { pick } from '@/src/lib/store';
import * as AssistantHeader from '@/src/modules/assistant/components/AssistantHeader';
import { AssistantQuickActions } from '@/src/modules/assistant/components/AssistantQuickActions';
import { useAssistantStore } from '@/src/modules/assistant/stores/assistantStore';
import AssistantIcon from '@/src/modules/resource-detail/components/ExpandedResource/ExtraPanel/Assistant/assets/assistant-tab-item-icon.svg';
import * as Sidebar from '@/src/modules/resource-detail/components/ExpandedResource/ExtraPanel/Sidebar';
import Modal, { defaultModalContentProps } from '@/src/modules/ui/components/Modal';
import * as Tabs from '@/src/modules/ui/components/Tabs';
import { P } from '@/src/modules/ui/components/Typography';
import { framerAnimationFade } from '@/src/modules/ui/constants/framerAnimations';
import { ForcedTheme } from '@/src/modules/ui/theme/ForcedTheme';
import useUIStore from '@/src/store/ui';
import { DialogClose, DialogContent } from '@radix-ui/react-dialog';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import { AnimatePresence } from 'framer-motion';
import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { shallow } from 'zustand/shallow';

const FixedSidebar = styled(Sidebar.Root)`
  position: fixed;
  right: 0.625rem;
  top: 0.625rem;
  height: calc(100% - 1.25rem);
  z-index: 125;
`;

interface ParentProps {
  animateIn?: boolean;
  chatAssistantOpen: boolean;
}

const StandaloneChatDesktop = forwardRef<HTMLDivElement, ParentProps>(
  ({ animateIn = true, chatAssistantOpen }, ref) => {
    const fadeColor = Sidebar.useSidebarScrollbarFadeColor();

    return (
      <MessagesProvider chatAssistantOpen={chatAssistantOpen}>
        <DialogContent {...defaultModalContentProps} aria-describedby={undefined}>
          <VisuallyHidden>
            <Modal.Title>Ask Fabric Assistant</Modal.Title>
          </VisuallyHidden>

          <ForcedTheme colorScheme="dark" asChild>
            <FixedSidebar ref={ref} {...(animateIn && framerAnimationFade)}>
              <Tabs.Root defaultValue="assistant" asChild>
                <Sidebar.Content>
                  <Sidebar.Header>
                    <DialogClose asChild>
                      <Sidebar.CloseButton />
                    </DialogClose>
                    <Tabs.List variant="flush">
                      <Tabs.Trigger variant="apparent" value="assistant" ignoreState disabled>
                        <AssistantIcon style={{ width: 20, height: 20 }} />
                        Ask Fabric Assistant
                      </Tabs.Trigger>
                    </Tabs.List>
                  </Sidebar.Header>

                  <Tabs.Content
                    value="assistant"
                    style={{ flexGrow: 1, overflow: 'hidden', flexDirection: 'column' }}
                  >
                    <AssistantHeader.Root>
                      <AssistantHeader.Left>
                        <P size="xs">Asking about:</P>
                        <AssistantHeader.ContextSelector />
                      </AssistantHeader.Left>
                      <AssistantHeader.ResetButton />
                    </AssistantHeader.Root>

                    <MessagesList fadeColor={fadeColor} />
                    <AssistantQuickActions />

                    <ForcedTheme colorScheme="light">
                      <AnimatePresence initial={false}>
                        <MessageForm autoFocus />
                      </AnimatePresence>
                    </ForcedTheme>
                  </Tabs.Content>
                </Sidebar.Content>
              </Tabs.Root>
            </FixedSidebar>
          </ForcedTheme>
        </DialogContent>
      </MessagesProvider>
    );
  },
);

StandaloneChatDesktop.displayName = 'StandaloneChatDesktop';

const StandaloneChatMobile = forwardRef<HTMLDivElement, ParentProps>(
  ({ chatAssistantOpen }, ref) => {
    return (
      <MessagesProvider chatAssistantOpen={chatAssistantOpen}>
        <Modal.Content respectMobileKeyboard ref={ref} aria-describedby={undefined} gap="none">
          <VisuallyHidden>
            <Modal.Title>Ask Fabric Assistant</Modal.Title>
          </VisuallyHidden>

          <AssistantHeader.Root>
            <AssistantHeader.ResetButton size="sm" roundness="default" />
            <AssistantHeader.ContextSelector
              triggerProps={{
                size: 'sm',
                roundness: 'default',
              }}
            />
            <Modal.Close variant="bg-contrast-reverse-semi-transparent" noAutoMargin />
          </AssistantHeader.Root>

          <MessagesList fadeColor="color-bg-primary" />
          <AssistantQuickActions />

          <AnimatePresence initial={false}>
            <ForcedTheme colorScheme="light">
              <MessageForm permanentFocus />
            </ForcedTheme>
          </AnimatePresence>
        </Modal.Content>
      </MessagesProvider>
    );
  },
);

StandaloneChatMobile.displayName = 'StandaloneChatMobile';

export const StandaloneChat: React.FC = () => {
  const { isMobileView } = useResponsive();

  const { expandedFdocId } = useUIStore((s) => pick(s, ['expandedFdocId']), shallow);
  const { chatAssistantOpen, setChatAssistantOpen } = useAssistantStore((s) =>
    pick(s, ['chatAssistantOpen', 'setChatAssistantOpen']),
  );

  const deferredExpandedFdocId = useDeferred(expandedFdocId);

  const animateIn = deferredExpandedFdocId === null && expandedFdocId === null;

  const Content = isMobileView ? StandaloneChatMobile : StandaloneChatDesktop;

  return (
    <Modal open={chatAssistantOpen && !expandedFdocId} onOpenChange={setChatAssistantOpen}>
      <Modal.Portal>
        <Modal.Overlay visibleOnMobileViewport />
        <Content animateIn={animateIn} chatAssistantOpen={chatAssistantOpen} />
      </Modal.Portal>
    </Modal>
  );
};
