import React from 'react';

export function useRandomIntervalFn(
  disabled: boolean,
  fn: VoidFunction,
  minMs: number = 0,
  maxMs: number = 0,
) {
  const timeoutFn = React.useRef(fn);

  timeoutFn.current = fn;

  React.useEffect(() => {
    if (disabled) return;

    let timeout: number | undefined = undefined;

    const setTimer = () => {
      timeout = window.setTimeout(
        () => {
          timeoutFn.current();
          setTimer();
        },
        Math.random() * (maxMs - minMs) + minMs,
      );
    };

    setTimer();

    return () => {
      timeout && window.clearTimeout(timeout);
    };
  }, [disabled, fn, minMs, maxMs]);
}
