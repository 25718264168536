import { FC } from 'react';
import { IconProps } from './types';
import clsx from 'clsx';

const QueueCircleIcon: FC<IconProps> = ({
  color = 'currentColor',
  size = 18,
  strokeWidth = 1.5,
  className,
  ...props
}) => {
  return (
    <svg
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={clsx('new_icon', className)}
      {...props}
    >
      <path
        d="M2 9C2 12.866 5.13401 16 9 16C12.866 16 16 12.866 16 9C16 5.13401 12.866 2 9 2C6.86603 2 4.95509 2.95489 3.67115 4.4607"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
      <path
        d="M10.8108 2.23856C7.07652 1.23797 3.23816 3.45404 2.23757 7.18831C1.23698 10.9226 3.45306 14.7609 7.18732 15.7615C10.9216 16.7621 14.7599 14.546 15.7605 10.8118C16.3128 8.75052 15.8851 6.65754 14.7629 5.02762"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
      <path
        d="M9 4.7998V8.9998H13.2"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
    </svg>
  );
};

export default QueueCircleIcon;
