import { useCallback } from 'react';
import { shallow } from 'zustand/shallow';
import {
  FileUploadError,
  FileUploadErrorCodes,
  MAX_TREE_DEPTH_ALLOWED,
} from '../lib/bulkUploader/uploader';
import useFileUploadStore, { MAX_UPLOAD_SIZE_FREE } from '../store/fileUploadStore';
import { isSubscribedPlan } from '../types/pricing';
import { useAuthUser } from './auth';

const traverseFileTree = async (
  items: FileSystemEntry[],
  callback: (files: { file: File; path: string }[]) => void,
  errorHandler: (error: Error) => void,
  path = '',
  depth = 1,
) => {
  if (depth > MAX_TREE_DEPTH_ALLOWED) {
    errorHandler(
      new FileUploadError(FileUploadErrorCodes.FileTreeTooDeep, 'File tree is too deep'),
    );
    return;
  }

  const toAdd = [];

  for (const item of items) {
    if (item.name.startsWith('.')) continue;

    if (item.isFile) {
      toAdd.push(item);
    } else if (item.isDirectory) {
      const reader = (item as FileSystemDirectoryEntry).createReader();

      // have to call readEntries() multiple times until we get all the entries
      // https://developer.mozilla.org/en-US/docs/Web/API/FileSystemDirectoryReader/readEntries
      new Promise((resolve, reject) => {
        const doBatch = () => {
          reader.readEntries((entries) => {
            if (entries.length > 0) {
              traverseFileTree(entries, callback, errorHandler, path + item.name + '/', depth + 1);
              doBatch();
            } else {
              resolve(null);
            }
          }, reject);
        };
        doBatch();
      });
    }
  }

  const files = await Promise.all(
    toAdd.map(
      (item) =>
        new Promise<{ file: File; path: string }>((resolve) => {
          (item as FileSystemFileEntry).file((file) => resolve({ file, path }));
        }),
    ),
  );

  callback(files);
};

const useFilesUpload = (callback?: () => void) => {
  const user = useAuthUser();
  const { addFile, addFiles, setTooDeep, clearFiles, incrementTooBigFiles } = useFileUploadStore(
    (state) => ({
      addFile: state.addFile,
      addFiles: state.addFiles,
      setTooDeep: state.setTooDeep,
      incrementTooBigFiles: state.incrementTooBigFiles,
      clearFiles: state.clearFiles,
    }),
    shallow,
  );

  const handleFile = useCallback(
    (file: File) => {
      // if the file is above the max size, we skip it

      if (
        (file as File).size > MAX_UPLOAD_SIZE_FREE &&
        !isSubscribedPlan(user?.subscription.tier)
      ) {
        incrementTooBigFiles();
        return;
      }

      // if it's an hidden file, we skip it
      if ((file as File).name.startsWith('.')) return;

      addFile(file as File);
    },
    [addFile, incrementTooBigFiles, user?.subscription.tier],
  );

  const fileUpload = useCallback(
    async (files: (File | DataTransferItem)[]) => {
      if (!files.length) {
        throw new Error('No file was detected');
      }

      callback?.();

      if (files.every((f) => f instanceof File)) {
        for (const file of files) {
          handleFile(file as File);
        }
        return;
      }

      // help typescript be smarter by force casting the files to DataTransferItem
      const dataTransferItems = files as DataTransferItem[];

      // if it's a DataTransferItem, we check if it's a folder and if so we traverse it, otherwise we just add the file to the list
      for (const item of dataTransferItems) {
        if (item instanceof File) {
          handleFile(item);
          continue;
        }

        let entry: FileSystemEntry | null = null;

        if (item.webkitGetAsEntry) {
          // Chrome, Edge, Safari
          entry = item.webkitGetAsEntry();
        } else {
          // Firefox
          // @ts-expect-error - Firefox doesn't have the method
          entry = item.getAsEntry();
        }

        if (!entry || entry.isFile) {
          const file = item.getAsFile();

          if (!file || file.name.startsWith('.')) continue;

          if (file.size > MAX_UPLOAD_SIZE_FREE && !isSubscribedPlan(user?.subscription.tier)) {
            incrementTooBigFiles();
            continue;
          }

          addFile(file);
        }

        if (!entry?.isDirectory) continue;

        const folderName = entry.name;

        if (folderName.startsWith('.')) continue;

        traverseFileTree(
          [entry],
          (files) => {
            addFiles(
              files.filter((f) => {
                if (
                  f.file.size > MAX_UPLOAD_SIZE_FREE &&
                  !isSubscribedPlan(user?.subscription.tier)
                ) {
                  incrementTooBigFiles();
                  return false;
                }
                return true;
              }),
            );
          },
          (e) => {
            if (e instanceof FileUploadError && e.code === FileUploadErrorCodes.FileTreeTooDeep) {
              clearFiles();
              setTooDeep(true);
              return;
            }
          },
        );
      }
    },
    [
      callback,
      user?.subscription.tier,
      addFile,
      incrementTooBigFiles,
      clearFiles,
      setTooDeep,
      handleFile,
      addFiles,
    ],
  );

  return fileUpload;
};

export default useFilesUpload;
