import { useEffect, useState } from 'react';

/**
 * Will check the time and return if it's late or not.
 * (late: after 11:00 PM and before 6:00 AM)
 */
const useIsLate = () => {
  const [isLate, setIsLate] = useState(false);

  useEffect(() => {
    const now = new Date();
    const hours = now.getHours();

    setIsLate(hours >= 23 || hours < 6);

    const interval = setInterval(
      () => {
        const now = new Date();
        const hours = now.getHours();

        setIsLate(hours >= 23 || hours < 6);
      },
      60 * 1000 * 5,
    );

    return () => {
      clearInterval(interval);
    };
  }, []);

  return isLate;
};

export default useIsLate;
