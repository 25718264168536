import AssistantButton from '@/src/components/AssistantButton/AssistantButton';
import { pick } from '@/src/lib/store';
import { StandaloneChat } from '@/src/modules/assistant/components/StandaloneChat';
import { useAssistantStore } from '@/src/modules/assistant/stores/assistantStore';
import useUIStore, { useSearchOverlay } from '@/src/store/ui';
import { isTruthy } from '@/src/utils/guards';
import { useRouter } from 'next/router';
import { shallow } from 'zustand/shallow';

export const Assistant = () => {
  const router = useRouter();
  const isNotificationsPage = router.pathname === '/notifications';
  const { isOverlayOpen: isSearchOverlayOpen } = useSearchOverlay();

  const { setChatAssistantOpen } = useAssistantStore();

  const { sidebarOpen, expandedFdocId } = useUIStore(
    (state) => pick(state, ['expandedFdocId', 'sidebarOpen']),
    shallow,
  );

  const onClickOpenChatbot = () => {
    setChatAssistantOpen(true);
  };

  /**
   * using some to have a bit better readability
   */
  const isHidden = [
    // on notifications page
    isNotificationsPage,
    // on desktop with expanded fdoc and the expanded fdoc sidebar is open
    isSearchOverlayOpen,
    sidebarOpen,
    expandedFdocId,
  ].some(isTruthy);

  if (isHidden) {
    return null;
  }

  return (
    <>
      <AssistantButton onClick={onClickOpenChatbot} />
      <StandaloneChat />
    </>
  );
};
