import { Button } from '@/src/modules/ui/components/Button';
import { Flex } from '@/src/modules/ui/components/Flex';
import { mediaHover, mediaMobile } from '@/src/modules/ui/styled-utils';
import { cssVar } from '@/src/modules/ui/theme/variables';
import { motion } from 'framer-motion';
import styled from 'styled-components';

/**
 * overlay otside the SidebarContainer viewport - lower zindex
 */
export const CloseOverlay = styled.div`
  ${mediaMobile} {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 29;
  }
`;

export const SidebarContainer = styled(motion.div)`
  background: ${cssVar['color-bg-primary']};
  border-right: 1px solid ${cssVar['color-border-primary']};
  padding-bottom: env(safe-area-inset-bottom);
  padding-top: env(safe-area-inset-top);
  touch-action: none;

  ${mediaMobile} {
    position: absolute;
    z-index: 30;
    bottom: var(--keyboard-height, 0px);
    max-width: 100dvw;
  }
`;

export const MobileCloseButton = styled(Button).attrs({
  variant: 'transparent',
  shape: 'square',
  size: 'sm',
})`
  top: calc(1rem);
  height: 48px;

  position: absolute;
  z-index: 40;
  align-items: center;
  right: 0.25rem;
  color: ${cssVar['color-text-primary']};
  display: none;
  ${mediaHover} {
    &:hover,
    &:focus {
      background: ${cssVar['color-bg-tertiary']};
    }
  }
  ${mediaMobile} {
    display: flex;
  }

  svg {
    transform: translateX(-1px) rotate(180deg);
  }
`;

export const SidebarBottomContent = styled.div`
  min-width: 0px;
  width: 100%;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  flex-shrink: 0;
  ${mediaMobile} {
    padding: 1rem;
  }
`;

export const SearchButtonContainer = styled.div`
  padding: 0.5rem;
  padding-bottom: 0;
  background: ${cssVar['color-bg-primary']};
`;

export const SearchButton = styled.button`
  background: ${cssVar['color-bg-primary']};
  border: 1px solid ${cssVar['color-border-primary']};
  color: ${cssVar['color-text-primary']};
  padding-right: 0.5rem;
  gap: 0.125rem;
`;

export const SearchButtonIconWrapper = styled(Flex).attrs({
  justifyContent: 'center',
  alignItems: 'center',
})`
  width: 40px;
  height: 40px;
  flex-shrink: 0;
`;

export const SearchButtonPlaceholder = styled.span`
  color: ${cssVar['color-text-placeholder']};
`;

export const QuestionMarkCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.5rem;
  width: 1.5rem;
  background: rgba(${cssVar['color-text-primary-rgb']}, 0.1);
  color: ${cssVar['color-text-primary']};
  flex-shrink: 0;
  border-radius: 50%;
`;

export const SidebarButton = styled(Button).attrs((props) => ({
  variant: 'semi-transparent',
  ...props,
}))`
  width: 100%;
  justify-content: flex-start;
  padding: 0 0.5rem;
  gap: 0.75rem;
  color: ${cssVar['color-text-quaternary']};
  font-size: 0.875rem;
  font-weight: 500;
  ${mediaHover} {
    &:hover,
    &:focus {
      outline: none;
    }
  }

  ${mediaMobile} {
    font-size: 1.125rem;
  }
`;
