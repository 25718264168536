import { useEffect } from 'react';
import { setNewModalOpen } from '../store/ui';

export const useAltN = () => {
  const handler = async (e: KeyboardEvent) => {
    if (e.altKey && e.code === 'KeyN') {
      e.preventDefault();
      e.stopPropagation();
      setNewModalOpen(true);
    }
  };
  useEffect(() => {
    window.addEventListener('keydown', handler);
    return () => window.removeEventListener('keydown', handler);
  });
};
