import { mediaMobile } from '@/src/modules/ui/styled-utils';
import { cssVar } from '@/src/modules/ui/theme/variables';
import styled from 'styled-components';

export const BulkUploaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${cssVar['color-bg-primary']};
  z-index: 17;
  position: absolute;
  bottom: 0;
  width: 100%;

  max-height: 50vh;
  border-top: 1px solid ${cssVar['color-border-primary']};
  color: ${cssVar['color-text-primary']};

  ${mediaMobile} {
    border-bottom: 1px solid ${cssVar['color-border-primary']};
    position: sticky;
  }
`;

export const HeaderTitle = styled.div`
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 50px;
  flex: 1 0 0;

  color: ${cssVar['color-text-tertiary']};
  text-align: right;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  white-space: nowrap;

  ${mediaMobile} {
    justify-content: flex-start;
    gap: 24px;
    padding: 10px 7px 10px 0;
  }
`;
