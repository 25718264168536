import { IconProps } from './types';

const TagsIcon: React.FC<IconProps> = ({ color = 'currentColor', className, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        stroke={color}
        strokeWidth="1.5"
        d="M6.438 13.154a1.25 1.25 0 0 1-.364-.943l.225-4.725a1.25 1.25 0 0 1 1.189-1.189l4.725-.225a1.25 1.25 0 0 1 .943.365l6.445 6.445a1.25 1.25 0 0 1 0 1.768l-4.95 4.95a1.25 1.25 0 0 1-1.767 0l-6.446-6.446Z"
      />
      <circle cx="9.879" cy="9.875" r="1" fill={color} transform="rotate(-45 9.879 9.875)" />
    </svg>
  );
};

export default TagsIcon;
