import { TreeItem } from './types';

// Find the first node which starts with the key
export function findNode(nodes: TreeItem[], key: string): TreeItem | null {
  for (const node of nodes) {
    if (node.name.toLowerCase().startsWith(key.toLowerCase())) {
      return node;
    }

    if (node.children) {
      const foundNode = findNode(node.children, key);
      if (foundNode) {
        return foundNode;
      }
    }
  }

  return null;
}

// Find the path to the node
export function findParents(data: TreeItem[], nodeId: string): string[] | null {
  for (const node of data) {
    if (node.children) {
      const childIndex = node.children.findIndex((child) => child.id === nodeId);

      if (childIndex !== -1) {
        return [node.id];
      }

      // else, search in child nodes
      const result = findParents(node.children, nodeId);
      if (result !== null) {
        // if child was found in sub-tree, add this node.id as part of the path
        return [node.id].concat(result);
      }
    }
  }
  return null;
}
