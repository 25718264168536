import { useIntervalFn } from '@/src/hooks/useInterval';
import { useRef, useState } from 'react';

/**
 * A hook to handle petting the mascot.
 * Returns a pet amount, and functions to spread on an element that will trigger the petting.
 * The pet amount is from 0-100, and will gradually decrease over time.
 *
 * It will detect petting by detecting touch events.
 */
const usePet = (onClick?: () => void) => {
  const [petAmount, setPetAmount] = useState(0);
  const [isPetting, setIsPetting] = useState(false);
  const [touchDown, setTouchDown] = useState(false);

  const isPettingRef = useRef(isPetting);
  isPettingRef.current = isPetting;

  useIntervalFn(
    isPetting,
    () => {
      setPetAmount((prev) => Math.max(0, prev - Math.max(1, Math.round(prev / 20))));
    },
    250,
  );

  const lastPosition = useRef({ clientX: 0, clientY: 0 });
  const lastMoveEvent = useRef<number>(0);

  // Detect petting, we check if the user holds and moves their finger around the mascot to pet it
  const onPointerMove = (e: React.PointerEvent) => {
    if (!touchDown) {
      setIsPetting(false);
      return;
    }

    if (Date.now() - lastMoveEvent.current < 100) return;
    lastMoveEvent.current = Date.now();

    const deltaX = e.clientX - lastPosition.current.clientX;
    const deltaY = e.clientY - lastPosition.current.clientY;

    lastPosition.current = { clientX: e.clientX, clientY: e.clientY };

    const distance = Math.sqrt(deltaX ** 2 + deltaY ** 2);

    if (distance < 20) return;

    setIsPetting(true);
    setPetAmount((prev) => Math.min(100, prev + Math.min(5, distance)));
  };

  const onPointerDown = (e: React.PointerEvent) => {
    if (e.button !== 0) return;

    setTouchDown(true);
    setIsPetting(false);

    lastPosition.current = { clientX: e.clientX, clientY: e.clientY };

    window.addEventListener('pointerup', onPointerUp, {
      once: true,
      capture: true,
    });
  };

  const onPointerUp = (e: React.PointerEvent | PointerEvent) => {
    if (e.button !== 0) return;

    if (isPettingRef.current) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      onClick?.();
    }

    setTouchDown(false);
    setIsPetting(false);
    lastPosition.current = { clientX: 0, clientY: 0 };

    return !isPetting;
  };

  return {
    petAmount,
    isPetting,
    events: {
      onPointerMove,
      onPointerDown,
    },
  };
};

export default usePet;
