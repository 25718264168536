import { useResponsive } from '@/src/hooks/responsive';
import ScrollArea from '@/src/modules/ui/components/ScrollArea';
import { mediaMobile } from '@/src/modules/ui/styled-utils';
import clsx from 'clsx';
import { motion, MotionValue, useWillChange } from 'framer-motion';
import styled from 'styled-components';

type TreeProps = {
  children: React.ReactNode;
  width: MotionValue<number> | number;
};

const MobileContainer = styled.div``;

const TreeList = styled(motion.ul)`
  min-width: 0px;
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  ${mediaMobile} {
    padding: 1rem;
    padding-right: 2.5rem;
  }
`;

const Tree = ({ children, width }: TreeProps) => {
  const willChange = useWillChange();

  const { isMobileView } = useResponsive();

  /**
   * on mobile resolution we're disabling all properties which would allow scrolling
   * it breaks the behaviour of DashboardSidebarV2 panning to close the sidebar
   */
  const Container = isMobileView ? MobileContainer : ScrollArea;

  return (
    <motion.div
      className={clsx(
        'flex flex-grow transition-transform duration-500 ease-[cubic-bezier(0.32,0.72,0,1)] relative w-full min-h-0',
      )}
      style={{
        width: width,
        willChange,
      }}
    >
      <div
        className={clsx(
          'overflow-hidden flex flex-col gap-3 transition-[opacity] duration-300 ease-[cubic-bezier(.075,.82,.165,1)] max-w-lg w-full min-w-0 flex-shrink',
        )}
      >
        <Container
          scrollbarVariant="subtle"
          className={clsx('flex-grow min-w-0 w-full relative', {
            'overflow-x-hidden': !isMobileView,
          })}
        >
          <TreeList
            role="tree"
            aria-label="Sidebar menu"
            style={{
              width: width,
            }}
          >
            {children}
          </TreeList>
        </Container>
      </div>
    </motion.div>
  );
};

export default Tree;
