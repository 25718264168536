import { useEffect, useRef, useState } from 'react';

type InactivityOptions = {
  inactiveAfter?: number;
  onInactive?: () => void;
  onActive?: () => void;
};

/**
 * A hook to detect inactivity, this will trigger a callback when the user is inactive for a certain amount of time (provided in ms).
 * Will also trigger the callback when the user is active again.
 * Detects inactivity by detecting mouse movement, key presses and touch events.
 */
const useDetectInactivity = ({ inactiveAfter = 5000, onInactive, onActive }: InactivityOptions) => {
  const [isActive, setIsActive] = useState(true);

  const onActiveRef = useRef(onActive);
  onActiveRef.current = onActive;
  const onInactiveRef = useRef(onInactive);
  onInactiveRef.current = onInactive;

  const isActiveRef = useRef(isActive);
  isActiveRef.current = isActive;

  const lastActivity = useRef<number>(Date.now());

  useEffect(() => {
    let focused = true;

    const onActivity = () => {
      lastActivity.current = Date.now();
      if (!isActiveRef.current && document.visibilityState === 'visible' && focused) {
        setIsActive(true);
        onActiveRef.current?.();
      }
    };

    const onActivityEvent = () => {
      onActivity();
    };

    const onFocus = () => {
      focused = true;
      onActivity();
    };

    const onBlur = () => {
      focused = false;
    };

    window.addEventListener('mousemove', onActivityEvent);
    window.addEventListener('keydown', onActivityEvent);
    window.addEventListener('touchstart', onActivityEvent);
    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);

    const interval = setInterval(() => {
      if (Date.now() - lastActivity.current > inactiveAfter) {
        setIsActive(false);
        onInactive?.();
      }
    }, inactiveAfter);

    return () => {
      window.removeEventListener('mousemove', onActivityEvent);
      window.removeEventListener('keydown', onActivityEvent);
      window.removeEventListener('touchstart', onActivityEvent);
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);

      clearInterval(interval);
    };
  }, [inactiveAfter, onInactive, onActive]);

  return isActive;
};

export default useDetectInactivity;
