import useAuthStore from '@/src/hooks/auth';
import { useOnClickOutside } from '@/src/hooks/outside';
import { useResponsive } from '@/src/hooks/responsive';
import useUIStore, { useExperimentSearchOverlay, useSearchOverlay } from '@/src/store/ui';
import clsx from 'clsx';
import {
  AnimatePresence,
  motion,
  useMotionValue,
  useMotionValueEvent,
  useTransform,
  useWillChange,
} from 'framer-motion';
import { useRouter } from 'next/router';
import React, { useEffect, useMemo, useRef } from 'react';
import { shallow } from 'zustand/shallow';

import ChevronRightThinIcon from '@/public/images/icons/ChevronRightThin.svg';
import InvertedFabricIcon from '@/public/images/icons/InvertedFabric.svg';
import {
  CloseOverlay,
  MobileCloseButton,
  QuestionMarkCircle,
  SearchButton,
  SearchButtonContainer,
  SearchButtonIconWrapper,
  SearchButtonPlaceholder,
  SidebarBottomContent,
  SidebarButton,
  SidebarContainer,
} from '@/src/components/DashboardSidebarV2/components/SidebarComponents';
import { useClientLayoutEffect } from '@/src/hooks/useClientLayoutEffect';
import SearchIcon from '@/src/icons/SearchIcon';
import TagsIcon from '@/src/icons/TagsIcon';
import { pick } from '@/src/lib/store';
import { useQueryResourceRootIntegrationList } from '@/src/modules/connections/queries/useQueryResourceRootIntegrationList';
import { useQueryResourceRootSpaceSystemList } from '@/src/modules/spaces/queries/useQueryResourceRootSpaceSystemList';
import { filterSpaces } from '@/src/modules/spaces/utils/filterSpaces';
import { framerAnimationFade } from '@/src/modules/ui/constants/framerAnimations';
import { usePanning } from '@/src/modules/ui/hooks/usePanning';
import AvatarUser from '@/src/modules/user/components/AvatarUser/AvatarUser';
import { toast } from '@/src/store/alerts';
import { hasMacOSKbd } from '@/src/types/global';
import Tooltip from '@/src/ui/Tooltip';
import Link from 'next/link';
import Handle from './Handle';
import { TreeView } from './TreeView';
import { TreeItem } from './TreeView/types';
import { SIDEBAR_OPTIONS } from './constants';
import { ConnectionsIcon, SpacesIcon, TimelineIcon } from './icons';

/**
 * mobile version was developed ad hoc
 * https://discord.com/channels/934045073002364981/1242068287416696872/1242082993854746689
 */

const DashboardSidebarV2: React.FC = () => {
  const ref = useRef<HTMLDivElement | null>(null);
  const { isMobileView } = useResponsive();
  const user = useAuthStore((state) => state.user, shallow);
  const router = useRouter();

  const { spaceRoots, isLoading: spaceRootsLoading } = useQueryResourceRootSpaceSystemList(
    undefined,
    { includeInbox: true },
    {
      refetchInterval: 60 * 1000,
    },
  );

  const spaceRootsSorted = useMemo(() => {
    return filterSpaces(spaceRoots, {
      sort: {
        field: 'title',
        order: 'asc',
      },
    });
  }, [spaceRoots]);

  const { integrationRoots, isLoading: integrationRootsLoading } =
    useQueryResourceRootIntegrationList();

  const { sidebarOpen, setSidebarOpen, sidebarExpanded, setSidebarExpanded } = useUIStore(
    (state) =>
      pick(state, ['sidebarOpen', 'setSidebarOpen', 'setSidebarExpanded', 'sidebarExpanded']),
    shallow,
  );

  const x = useMotionValue(
    sidebarExpanded
      ? useUIStore.getState().sidebarPreferredWidth
      : SIDEBAR_OPTIONS.UNEXPANDED_WIDTH,
  );

  const width = useTransform(x, (x) =>
    isMobileView
      ? 300
      : Math.max(
          Math.min(x, SIDEBAR_OPTIONS.EXPANDED_WIDTH.MAXIMUM),
          SIDEBAR_OPTIONS.UNEXPANDED_WIDTH,
        ) + 4,
  );

  const willChange = useWillChange();

  useMotionValueEvent(x, 'change', (latest) => {
    setSidebarExpanded(latest > SIDEBAR_OPTIONS.EXPANDED_WIDTH.MINIMUM);
  });

  // Makes sure the sidebar is closed when the width is less than the minimum when mounting
  useEffect(() => {
    if (useUIStore.getState().sidebarPreferredWidth < SIDEBAR_OPTIONS.EXPANDED_WIDTH.MINIMUM) {
      setSidebarExpanded(false);
    }
  }, [setSidebarExpanded]);

  const sidebarData: TreeItem[] = useMemo(
    () => [
      {
        id: 'home',
        name: 'Home',
        icon: <InvertedFabricIcon style={{ cursor: 'pointer' }} width="100%" height="20px" />,
        children: [],
        navigateTo: '/',
        newItemActions: false,
      },
      {
        id: 'timeline',
        name: 'Timeline',
        icon: <TimelineIcon style={{ cursor: 'pointer' }} width="100%" height="19px" />,
        children: [],
        navigateTo: '/timeline',
        newItemActions: false,
      },
      {
        id: 'spaces',
        name: 'Spaces',
        testId: 'sidebar-spaces-link',
        icon: <SpacesIcon style={{ cursor: 'pointer' }} width="100%" height="20px" />,
        navigateTo: '/spaces',
        newItemActions: true,
        newItemActionId: 'spaces',
        loading: spaceRootsLoading,
        children: spaceRootsSorted.map((spaceRoot) => ({
          id: spaceRoot.id,
          name: spaceRoot.folder.name || 'Untitled',
          isFolder: true,
          icon: (
            <SpacesIcon style={{ cursor: 'pointer', opacity: 0.75 }} width="100%" height="20px" />
          ),
          navigateTo: spaceRoot.subtype === 'inbox' ? '/saved' : `/spaces/${spaceRoot.folder.id}`,
          children: [],
          newItemActions: true,
          newItemActionId: 'subfolders',
        })),
      },
      {
        id: 'connections',
        name: 'Connections',
        icon: <ConnectionsIcon style={{ cursor: 'pointer' }} width="100%" height="20px" />,
        newItemActions: true,
        newItemActionId: 'connections',
        navigateTo: '/connections',
        // There is always 1 root, so if the root array is empty it means it hasn't loaded yet
        loading: integrationRootsLoading,
        children: integrationRoots?.map(
          ({ integration: { id, viewConfig, mirrorRegistries } }) => ({
            id,
            name: viewConfig.displayName,
            icon: <viewConfig.Icon {...viewConfig.IconProps} />,
            navigateTo: viewConfig.navigateTo,
            /**
             * if there's only one mirror registry, don't show the children
             * the item is the link itself
             */
            children:
              mirrorRegistries.length === 1
                ? []
                : mirrorRegistries.map((registry) => ({
                    id: registry.id,
                    name: registry.name,
                    navigateTo: `/folders/${registry.rootId}`,
                    icon: (
                      <SpacesIcon
                        style={{ cursor: 'pointer', opacity: 0.75 }}
                        width="100%"
                        height="20px"
                      />
                    ),
                  })),
          }),
        ),
      },
      {
        id: 'tags',
        name: 'Tags',
        icon: <TagsIcon style={{ cursor: 'pointer' }} />,
        testId: 'sidebar-tags-link',
        children: [],
        navigateTo: '/tags',
        newItemActions: false,
      },
    ],
    [spaceRootsSorted, spaceRootsLoading, integrationRoots, integrationRootsLoading],
  );

  const { openOverlay } = useSearchOverlay();
  const { openOverlay: openExperimentOverlay } = useExperimentSearchOverlay();

  /********************************************************
   * effects which close the mobile sidebar
   */

  /**
   * close the sidebar when clicking outside
   * we're rendering CloseOverlay so we prevent triggering of click events of other elements
   */
  useOnClickOutside(
    ref,
    () => {
      if (sidebarOpen) setSidebarOpen(false);
    },
    [],
    false,
  );

  /**
   * when navigation changes, close the sidebar
   */
  useEffect(() => {
    setSidebarOpen(false);
  }, [router.pathname, setSidebarOpen]);

  /**
   * set close state when not mobile
   */
  useClientLayoutEffect(() => {
    if (!isMobileView) {
      setSidebarOpen(false);
    }
  }, [isMobileView, setSidebarOpen]);

  /**
   *
   */

  const isInSettings = router.pathname.startsWith('/settings');

  const [experimentAttempts, setExperimentAttempts] = React.useState(0);

  const onRightClickGlobalSearch = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    setExperimentAttempts(experimentAttempts + 1);
    if (experimentAttempts < 9) {
      return;
    }

    if (experimentAttempts === 9)
      toast({
        content: 'Experimental search enabled.',
      });
  };

  /********************************************************************************************************
   * sidebar swipe to close
   */
  const [lastSwipeX, setLastSwipeX] = React.useState(0);

  // reset on open
  React.useEffect(() => {
    if (sidebarOpen) {
      setLastSwipeX(0);
    }
  }, [sidebarOpen]);

  const { motionProps, panningXOffset, isPanningX } = usePanning({
    touchOnly: false,
    preventEventsDefault: true,
    stopEventsPropagation: true,
    onPanEnd: (event, info, moreinfo) => {
      if (info.velocity.x < -50 && moreinfo.axis === 'x') {
        setSidebarOpen(false);
        setLastSwipeX(info.offset.x);
      }
    },
  });

  if (isInSettings) return null;

  return (
    <TreeView.Provider>
      <SidebarContainer
        style={{
          willChange,
          width,
          left: isMobileView ? `${sidebarOpen ? '0' : '-100%'}` : undefined,
          transform: isMobileView
            ? `translateX(${lastSwipeX || Math.min(panningXOffset, 0)}px)`
            : undefined,
        }}
        className={clsx(
          'md:border-none border-r top-0 md:relative md:left-0 md:bottom-0 md:top-0 sidebar-top md:z-10',
          'md:transition-none transition-[left] duration-300 ease-[cubic-bezier(.075,.82,.165,1)]',
          !sidebarOpen && 'pointer-events-none md:pointer-events-auto',
        )}
        ref={ref}
        {...motionProps}
        data-multiselect-clear
        {...framerAnimationFade}
      >
        <motion.section
          className="w-full h-full"
          style={{ pointerEvents: isPanningX ? 'none' : 'auto' }}
        >
          <aside className="relative h-full flex flex-col">
            {!isMobileView ? (
              <SearchButtonContainer>
                <SearchButton
                  onContextMenu={onRightClickGlobalSearch}
                  onClick={() =>
                    experimentAttempts >= 10 ? openExperimentOverlay() : openOverlay('global')
                  }
                  className={clsx(
                    'h-[40px] rounded-lg flex items-center w-full overflow-clip min-w-0 relative',
                  )}
                >
                  {sidebarExpanded && !isMobileView ? (
                    <SearchButtonIconWrapper>
                      <SearchIcon className="w-full shrink-0" />
                    </SearchButtonIconWrapper>
                  ) : (
                    <Tooltip label="Global Search" delay={1000} placement="right">
                      <SearchButtonIconWrapper>
                        <SearchIcon className="w-full shrink-0" />
                      </SearchButtonIconWrapper>
                    </Tooltip>
                  )}

                  <SearchButtonPlaceholder
                    className={clsx(
                      'text-sm font-medium truncate transition-[opacity,transform] ease-[cubic-bezier(.075,.82,.165,1)]',
                    )}
                  >
                    Global search
                  </SearchButtonPlaceholder>
                  <AnimatePresence>
                    {sidebarExpanded && !isMobileView ? (
                      <motion.span
                        className={clsx(
                          'truncate text-xs text-[#30F] py-1 px-2 bg-[#F1EDFD] rounded-[6px] ml-auto',
                        )}
                        transition={{
                          ease: [0.32, 0.72, 0, 1],
                        }}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                      >
                        {hasMacOSKbd() ? '⌘F' : 'Ctrl+F'}
                      </motion.span>
                    ) : null}
                  </AnimatePresence>
                </SearchButton>
              </SearchButtonContainer>
            ) : null}

            <TreeView width={width}>
              <MobileCloseButton
                onClick={() => {
                  setSidebarOpen(false);
                }}
              >
                <ChevronRightThinIcon />
              </MobileCloseButton>
              {sidebarData.map((item) => (
                <TreeView.Node parentNodeId={null} key={item.id} item={item} data={sidebarData} />
              ))}
            </TreeView>

            <Handle x={x} />

            <SidebarBottomContent>
              {!isMobileView && (
                <SidebarButton
                  as="a"
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://docs.fabric.so/"
                  draggable={false}
                >
                  <QuestionMarkCircle>?</QuestionMarkCircle>
                  Need help?
                </SidebarButton>
              )}
              <SidebarButton
                as={Link}
                href="/settings"
                draggable={false}
                data-testid="dashboard-user-avatar"
              >
                <AvatarUser user={user} size={24} tooltip={undefined} />
                {user?.name}
              </SidebarButton>
            </SidebarBottomContent>
          </aside>
        </motion.section>
      </SidebarContainer>
      {sidebarOpen && isMobileView && <CloseOverlay />}
    </TreeView.Provider>
  );
};

export default DashboardSidebarV2;
