import { DefaultSpaceFilterOptions, SpaceQueryParams } from '@/src/modules/spaces/spaces.types';

import dayjs from '@/src/lib/dayjs';
import { ResourceRoot } from '@/src/modules/resource-roots/resource-roots.types';

const sortSpaces = (field: SpaceQueryParams['sort'], order: SpaceQueryParams['order']) => {
  switch (field) {
    case 'title':
      return (a: ResourceRoot, b: ResourceRoot) => {
        if (a.subtype === 'inbox') return -1;
        if (b.subtype === 'inbox') return 1;

        const aTitle = a.folder.name || '';
        const bTitle = b.folder.name || '';

        return aTitle.localeCompare(bTitle) * (order === 'asc' ? 1 : -1);
      };
    case 'createdAt':
      return (a: ResourceRoot, b: ResourceRoot) => {
        if (a.subtype === 'inbox') return -1;
        if (b.subtype === 'inbox') return 1;
        return (
          (dayjs(a.createdAt).isSameOrAfter(b.createdAt) ? 1 : -1) * (order === 'asc' ? 1 : -1)
        );
      };
    case 'modifiedAt':
    default:
      return (a: ResourceRoot, b: ResourceRoot) => {
        if (a.subtype === 'inbox') return -1;
        if (b.subtype === 'inbox') return 1;
        return (
          (dayjs(a.modifiedAt).isSameOrAfter(b.modifiedAt) ? 1 : -1) * (order === 'asc' ? 1 : -1)
        );
      };
  }
};

/**
 * used to filter list of spaces based on search query params
 * @TODO make it as general filter root
 * @param data
 * @param filters
 * @returns
 */
export const filterSpaces = <T extends ResourceRoot>(
  roots: T[],
  filters: Partial<DefaultSpaceFilterOptions>,
): T[] => {
  if (!roots) return [];

  let nextData = roots;
  const { sort, keyword, privacy } = filters;

  if (privacy === 'private') {
    nextData = nextData.filter((root) => {
      return !root.folder.isPublic && root.folder.memberCount === 1;
    });
  } else if (privacy === 'shared') {
    nextData = nextData.filter((root) => {
      return root.folder.isPublic || root.folder.memberCount > 1;
    });
  }

  /**
   * filter by keyword
   */
  if (keyword) {
    nextData = nextData.filter((root) => {
      return (root.folder.name || '').toLowerCase().includes(keyword.trim().toLowerCase());
    });
  }

  /**
   * sort
   */
  return nextData.sort(sortSpaces(sort?.field || 'createdAt', sort?.order || 'desc'));
};
