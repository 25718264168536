import React from 'react';
import styles from './Progress.module.scss';
import * as RadixProgress from '@radix-ui/react-progress';

const Progress: React.FC<{
  progress: number;
}> = ({ progress }) => {
  return (
    <RadixProgress.Root className={styles.progress} value={progress}>
      <RadixProgress.Indicator
        className={styles.progress__bar}
        style={{ transform: `translateX(-${100 - progress}%)` }}
      />
    </RadixProgress.Root>
  );
};

export default Progress;
