import {
  BulkUploaderContainer,
  HeaderTitle,
} from '@/src/components/BulkUploaderDisplay/components';
import { useResponsive } from '@/src/hooks/responsive';
import { useDomContentRect } from '@/src/hooks/useDomRect';
import ChevronUpIcon from '@/src/icons/ChevronUpIcon';
import useBulkUploaderStore from '@/src/lib/bulkUploader/store';
import { FileStatus } from '@/src/lib/bulkUploader/uploader';
import { ButtonIcon } from '@/src/modules/ui/components/Button';
import Modal from '@/src/modules/ui/components/Modal';
import useUIStore from '@/src/store/ui';
import Tooltip from '@/src/ui/Tooltip';
import plur from 'plur';
import React, { useEffect, useMemo, useRef } from 'react';
import { createGlobalStyle } from 'styled-components';
import { shallow } from 'zustand/shallow';
import styles from './BulkUploaderDisplay.module.scss';
import BulkUploaderDisplayItem from './BulkUploaderDisplayItem';
import Progress from './Progress';

const BulkUploaderStyles = createGlobalStyle<{ bulkHeight?: number }>`
:root {
  ${(p) => {
    // wanted to re-use one of the existing ones but desktop doesn't use the global safe-offset-floating-elements, so it's best
    // to have a separate one for this
    return `
    --bulk-uploader-height: ${p.bulkHeight || 0}px;
    `;
  }}
  }
`;

const BulkUploaderDisplay: React.FC = () => {
  const [open, setOpen] = React.useState(true);
  const { isMobileView } = useResponsive();

  const rootRef = useRef<HTMLDivElement>(null);
  const [rootRect] = useDomContentRect(rootRef.current);
  const expandedFdocId = useUIStore((state) => state.expandedFdocId, shallow);

  const { tabId, files, uploadSpeed, clear } = useBulkUploaderStore((state) => {
    return {
      tabId: state.tabId,
      files: state.sharedFiles,
      uploadSpeed: state.uploadSpeed,
      clear: state.clear,
    };
  }, shallow);

  const flatFiles = useMemo(() => {
    return Object.entries(files).flatMap(([key, value]) => {
      return value.map((file) => {
        return {
          ...file,
          key,
        };
      });
    });
  }, [files]);

  const completedFiles = useMemo(() => {
    return flatFiles.filter((item) => {
      return item.status === FileStatus.Success || item.status === FileStatus.Failed;
    });
  }, [flatFiles]);

  const filesInProgress = useMemo(() => {
    return flatFiles.filter((item) => {
      return item.status === FileStatus.Uploading || item.status === FileStatus.Ready;
    });
  }, [flatFiles]);

  const [delayedUploadSpeed, setDelayedUploadSpeed] = React.useState(0);
  const lastUploadSpeedUpdate = React.useRef(0);

  useEffect(() => {
    if (uploadSpeed === 0) return;

    if (lastUploadSpeedUpdate.current + 1000 > Date.now()) return;

    lastUploadSpeedUpdate.current = Date.now();

    setDelayedUploadSpeed(uploadSpeed);
  }, [uploadSpeed]);

  // given the upload speed, calculate an approximate time remaining in seconds
  const timeRemaining = useMemo(() => {
    if (delayedUploadSpeed === 0) return null;

    // the upload speed is in MB/s, so we convert it to bytes/s
    const bytesPerSecond = delayedUploadSpeed * 1000000;

    // calculate the total size of all files in progress while removing
    // the percentage of each file that has already been uploaded
    const totalSize = filesInProgress.reduce((acc, item) => {
      return acc + item.file.size * (1 - item.progress / 100);
    }, 0);

    // calculate the total time remaining in seconds
    const secondsRemaining = totalSize / bytesPerSecond;

    return secondsRemaining;
  }, [filesInProgress, delayedUploadSpeed]);

  const progress = useMemo(() => {
    // from 0 to 100
    return (completedFiles.length / flatFiles.length) * 100;
  }, [completedFiles, flatFiles]);

  const toggleOpen = React.useCallback(() => {
    setOpen((s) => !s);
  }, []);

  const todoFileCount = flatFiles.length - completedFiles.length;

  const onClose = () => {
    if (
      !flatFiles.every(
        (item) => item.status === FileStatus.Success || item.status === FileStatus.Failed,
      )
    )
      return;

    clear();
  };

  const title = useMemo(() => {
    if (todoFileCount <= 0) return 'Upload complete';
    if (isMobileView) return `${todoFileCount} uploading`;
    return (
      `Uploading ${todoFileCount} of ${flatFiles.length} ${plur('file', flatFiles.length)}` +
      (timeRemaining ? ` ${timeRemaining.toFixed(0)} seconds left...` : '')
    );
  }, [todoFileCount, flatFiles.length, isMobileView, timeRemaining]);

  if (flatFiles.length === 0) return null;

  return (
    <BulkUploaderContainer ref={rootRef}>
      {!expandedFdocId && <BulkUploaderStyles bulkHeight={rootRect?.height} />}
      <div className={styles.header}>
        <HeaderTitle>
          <Tooltip label={todoFileCount > 0 ? `${delayedUploadSpeed.toFixed(2)} MB/s` : undefined}>
            <span>{title}</span>
          </Tooltip>
          {todoFileCount > 0 && <Progress progress={progress} />}
        </HeaderTitle>

        <div className={styles.header__actions}>
          <ButtonIcon size="sm" variant="bg-secondary" onClick={toggleOpen}>
            <ChevronUpIcon
              size={18}
              style={{
                transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
            />
          </ButtonIcon>

          <Tooltip label={todoFileCount > 0 ? 'Files uploading...' : undefined} delay={0}>
            <Modal.Close
              as="button"
              size="sm"
              onClick={onClose}
              data-testid="close-preview-button"
              disabled={todoFileCount > 0}
            />
          </Tooltip>
        </div>
      </div>
      {open && (
        <div className={styles.body}>
          {flatFiles.map((item) => {
            return (
              <BulkUploaderDisplayItem key={item.id} item={item} canInteract={item.key === tabId} />
            );
          })}
        </div>
      )}
    </BulkUploaderContainer>
  );
};

export default BulkUploaderDisplay;
