import { create } from 'zustand';

interface AnimationStore {
  pageLoadDashboardHeader: boolean;
  pageLoadDashboardSidebar: boolean;
  pageLoadContent: boolean;
  pageLoadAssistantButton: boolean;

  setPageLoadDashboardHeader: (pageLoadDashboardHeader: boolean) => void;
  setPageLoadContent: (pageLoadContent: boolean) => void;
  setPageLoadAssistantButton: (pageLoadAssistantButton: boolean) => void;
}

const useAnimationStore = create<AnimationStore>((set) => ({
  pageLoadDashboardHeader: false,
  pageLoadDashboardSidebar: false,
  pageLoadContent: false,
  pageLoadAssistantButton: false,

  setPageLoadDashboardHeader: (pageLoadDashboardHeader: boolean) =>
    set({ pageLoadDashboardHeader }),
  setPageLoadContent: (pageLoadContent: boolean) => set({ pageLoadContent }),
  setPageLoadAssistantButton: (pageLoadAssistantButton: boolean) =>
    set({ pageLoadAssistantButton }),
}));

export default useAnimationStore;
